import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from '../../../../play-core/hooks/use-search-params';
import { loadShowDetail } from '../../api/load-show-detail';
import { queryKeys } from '../../config/use-query';
import { useAxios } from '../../hooks/use-axios';
export const useShowDetails = initialData => {
  const axios = useAxios();
  const {
    id: showId = ''
  } = useSearchParams();
  const {
    data: show,
    isLoading,
    error
  } = useQuery({
    queryKey: [queryKeys.ShowDetail(showId), axios],
    queryFn: () => loadShowDetail(showId, axios),
    initialData: initialData?.show,
    enabled: !!showId
  });
  return {
    show,
    isLoading,
    error
  };
};